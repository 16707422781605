export interface Params {
  [template: string]: any;
  [template: number]: any;
}

/**
 * format
 * @param message - message
 * @param params - format style
 * @returns - formatted message
 */
function formatMessage(message: any, params?: Params) {
  if (!message || typeof message !== 'string' || !params) {
    return message;
  }
  const messageArr = message.split(/[{}]/);

  let messageFormat = '';
  for (let index = 0; index < messageArr.length; index += 1) {
    const item = messageArr[index];
    if (!item) {
      continue;
    }
    if (index % 2 !== 0) {
      if (item in params || Array.isArray(params)) {
        //@ts-ignore
        messageFormat += params[item];
      } else {
        throw new Error(`${item} not in params`);
      }
    } else {
      messageFormat += item;
    }
  }
  return messageFormat;
}

export { formatMessage };
