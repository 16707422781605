import '@mantine/charts/styles.css';
import '@mantine/core/styles.layer.css';
import '@mantine/dates/styles.css';
import { notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'mantine-contextmenu/styles.layer.css';
import 'mantine-datatable/styles.layer.css';
import ReactDOM from 'react-dom/client';
import App from './app';
import './layout.css';
import './styles/global.css';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: '😞',
        message: error?.message || 'error_occured'
      });
    }
  })
});

const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
};

const rootElement = document.querySelector('#root');

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(<Root />);
}
