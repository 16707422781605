import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { IApiResponse } from '../lib/api';
import { httpClient } from '../lib/http';

function useGetApi<O>(
  resourceUrl: string,
  resourceKey: string,
  options?: Omit<UseQueryOptions<IApiResponse<O>, unknown>, 'queryKey'>
) {
  return useQuery<IApiResponse<O>, unknown>({
    queryKey: [resourceKey, 'query'],
    queryFn: () => httpClient.get<IApiResponse<O>>(resourceUrl),
    ...options
  });
}

export default useGetApi;
