import { Button, Flex, Group, Stack, Text, Title } from '@mantine/core';
import classes from './fallback.module.css';
import { useTranslations } from './lib/i18n';

const BoundaryErrorFallback = () => {
  const t = useTranslations();
  return (
    <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap">
      <Stack h={300} className={classes.content}>
        <Title className={classes.title}>{t('common.something_went_wrong')}</Title>
        <Text c="dimmed" size="lg" className={classes.description}>
          {t('common.try_to_refresh_the_page')}
        </Text>
        <Group justify="center">
          <Button size="md" onClick={() => location.reload()}>
            {t('common.refresh_page')}
          </Button>
        </Group>
      </Stack>
    </Flex>
  );
};

export default BoundaryErrorFallback;
