import { adminAsyncRoutes, customerAsyncRoutes, workspaceAsyncRoutes } from './routes';

import { RoleEnum } from '@/types/enums';
import type { Role } from '../types/components';

const generateRoutes = (role: Role) => {
  if (role === RoleEnum.ADMIN) {
    return adminAsyncRoutes;
  }

  return [...customerAsyncRoutes, ...workspaceAsyncRoutes];
};

export { generateRoutes };
