const definitions = {
  required: 'Required',
  api_error: {
    required: 'required',
    quantity_is_required: 'Quantity is required',
    vat_percentage_is_required: 'VAT is required'
  },
  default_template: {
    title: 'Default template',
    plain: 'Plain',
    industrial: 'Industrial'
  },
  nav: {
    price_plans: 'Price plans',
    dashboard: 'Dashboard',
    users: 'Users',
    overview: 'Overview',
    invoices: 'Invoices',
    list: 'List',
    templates: 'Templates',
    proposals: 'Proposals',
    settings: 'Settings',
    clients: 'Clients',
    workspaces: 'Workspaces',
    my_profile: 'My profile',
    billing: 'Billing'
  },
  paper_size: {
    title: 'Paper size',
    letter: 'Letter',
    a4: 'A4'
  },
  common: {
    branding_text: 'This invoice was proudly created by tryinvoice.com',
    vat_id: 'VAT ID',
    client_id: 'Client ID',
    invoice_id: 'Invoice ID',
    invoice_no: 'Invoice No',
    invoice_date: 'Invoice date',
    invoice_due_date: 'Inoivce due date',
    proposal_expires_at: 'Proposal expires at',
    invoice: 'Invoice',
    description: 'Description',
    amount: 'Amount',
    total: 'Total',
    sub_total: 'Subtotal',
    unit_price: 'Unit price',
    quantity: 'Quantity',
    vat: 'VAT',
    or: 'Or',
    preview_pdf: 'Preview PDF',
    add_new_client: 'Add new client',
    purchase_reference: 'Purchase reference',
    status: 'Status',
    created: 'Created',
    paid: 'Paid',
    to: 'To',
    from: 'From',
    approved: 'Approved',
    sent: 'Sent',
    for_e_g: 'For e.g.',
    select_image: 'Select image',
    select_video: 'Select video',
    select_document: 'Select file',
    select_pdf: 'Select file',
    are_you_sure_you_want_to_set_template: 'Are you sure you want to set the following template as default?',
    template: {
      simple: 'Simple',
      default: 'Default',
      developerdark: 'Developer dark',
      developerlight: 'Developer light',
      flower: 'Flower',
      flower_subscribed_only: 'Flower (Subscription only)',
      simple_subscribed_only: 'Simple (Subscription only)',
      industrial: 'Industrial',
      industrial_subscribed_only: 'Industrial (Subscription only)',
      streetshop: 'Street shop'
    },
    month: {
      jan: 'Jan',
      feb: 'Feb',
      mar: 'Mar',
      apr: 'Apr',
      may: 'May',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Aug',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Dec'
    },
    confirm: 'Confirm',
    are_you_sure_you_want_to_delete: 'Are you sure you want to delete?',
    this_action_is_irreversible: 'This action is irreversible',
    cancel: 'Cancel',
    right_click_on_any_row_to_see_more_options: 'Right click on any rows to see more options',
    protip: 'Protip',
    click_on_any_row_to_see_detail: 'Click on any rows to see detail',
    click_or_right_click_on_any_row_to_see_more_options: 'Click or right click on any rows to see more options',
    create: 'Create',
    open_pdf: 'Open PDF',
    download_pdf: 'Download PDF',
    edit: 'Edit',
    delete: 'Delete',
    create_invoice: 'Create invoice',
    en: 'English',
    vi: 'Vietnamese',
    es: 'Spanish',
    successfully_update: 'Successfully update',
    last_update: 'Last update',
    successfully_save: 'Successfully save',
    save: 'Save',
    no_record_founds: 'No records found',
    something_went_wrong: 'We are having some issues',
    try_to_refresh_the_page: 'Can you try to refresh to page?',
    refresh_page: 'Refresh the page',
    loading: 'Loading',
    error: 'Error',
    success: 'Success',
    logout: 'Logout',
    select_media: 'Select media',
    page: 'Page',
    video: 'Video',
    rows_per_page: 'Rows per page',
    file_not_exceed: 'File not exceed',
    update: 'Update',
    enter_keyword_to_search: 'Enter a keyword to search',
    unable_to_load_data: 'Unable to load data',
    refresh: 'Refresh',
    last_updated_at: 'Last updated at',
    add_new: 'Add new',
    drag_file_or_click_files: 'Drag files here or click to select files',
    error_occured: 'Error occured',
    uploading: 'Uploading',
    select: 'Select',
    selected: 'Selected',
    no_result: 'No result',
    file_manager: 'File manager',
    your_browser_does_not_support_video_tag: 'Your browser does not support video tag'
  },
  header: {
    settings: 'Settings',
    account: 'Account',
    end_impersonate: 'End impersonate',
    version: 'Version',
    logout: 'Logout'
  },
  login_view: {
    title: 'Login',
    login: 'Login',
    email: 'Email',
    code: 'Code',
    request_login_code: 'Request login code',
    enter_your_email: 'Enter your email',
    enter_login_code: 'Enter login code',
    must_be_6_digits: 'Must be 6 digits'
  },
  subscription_view: {
    meta_title: 'Billing',
    billing: 'Billing',
    subscription: 'Subscription',
    manage: 'Manage',
    plan: 'Plan',
    billing_cancellation_help:
      'If you cancel your subscription, you need to re-subscribe with your same email to get access to your workspaces.'
  },
  profile_index: {
    email: 'Email',
    my_profile: 'My profile',
    title: 'Overview',
    billing: 'Billing',
    workspace_name: 'Workspace name',
    create: 'Create',
    workspaces: 'Workspaces',
    work_in_progress: 'Work in progress',
    name: 'Name',
    add_new_workspace: 'Add new workspace',
    create_new_workspace: 'Create new workspace',
    select_workspace: 'Select workspace'
  },
  overview_view: {
    meta_title: 'Overview',
    this_year: 'This year',
    this_month: 'This month',
    recent_clients: 'Recent clients',
    recent_proposals: 'Recent proposals',
    unpaid_invoices: 'Unpaid invoices',
    invoiced: 'Invoiced'
  },
  client_view: {
    title: 'Clients',
    item: 'Item',
    client_detail: 'Client detail',
    clients: 'Clients',
    new_client: 'New client',
    update_client: 'Update client',
    default_language: 'Default language',
    country: 'Country',
    city: 'City',
    email: 'Email',
    phone: 'Phone',
    language: 'Language',
    address: 'Address',
    address_line: 'Address line',
    zipcode: 'Zipcode',
    name: 'Name',
    modify: 'Modify',
    vat_id: 'VAT ID',
    select_unit: 'Select unit',
    your_reference: 'Your reference',
    your_reference_help: 'Add your own unique client reference number for this client',
    invoices: 'Invoices',
    proposals: 'Proposals',
    invoiced_amount: 'Invoiced amount'
  },
  proposal_view: {
    meta_title: 'Proposals',
    client: 'Client',
    new_proposal: 'New proposal',
    date: 'Date',
    title: 'Title',
    total: 'Total',
    status: 'Status',
    proposals: 'Proposals',
    proposal_title: 'Proposal title',
    proposal_template: 'Proposal template',
    amount: 'Amount',
    expires_in: 'Expires in',
    proposed_at: 'Proposed at',
    proposal_text: 'Proposal text',
    purchase_reference: 'Purchase reference',
    proposal_number: 'Proposal number',
    proposal_number_help: 'Add your own unique proposal reference number for this proposal',
    proposal_language: 'Proposal language',
    update_proposal: 'Update proposal'
  },
  invoice_view: {
    meta_title: 'Invoices',
    reference_number: 'Reference number',
    add_note: 'Add note',
    update_invoice: 'Update invoice',
    unit_cost: 'Unit cost',
    another_sub_item: 'Another subitem',
    quantity: 'Quantity',
    description: 'Description',
    add_description: 'Add description',
    remove_description: 'Remove description',
    remove: 'Remove',
    payable_within: 'Payable within',
    days: 'days',
    subtotal: 'Subtotal',
    qty: 'Qty',
    vat: 'VAT',
    vat_with_percentage: 'VAT(%)',
    add_vat: 'Add VAT',
    amount: 'Amount',
    line_items: 'Line items',
    sub_item: 'Subitem',
    unit: 'Unit',
    invoices: 'Invoices',
    invoice_date: 'Invoice date',
    select_client: 'Select client',
    select_unit: 'Select unit',
    search_client: 'Search client',
    invoice_due_date: 'Invoice due date',
    client: 'Client',
    new_invoice: 'New invoice',
    invoice_language: 'Invoice language',
    invoice_number: 'Invoice number',
    invoice_number_help: 'Add your own unique invoice reference number for this invoice',
    invoice_text: 'Invoice text',
    payment_detail_and_additional_notes: 'Payment details and additional notes',
    invoice_template: 'Invoice template',
    invoice_purchase_reference: 'Invoice purchase reference',
    date: 'Date',
    title: 'Title',
    item: 'Item',
    items: 'Items',
    total: 'Total',
    status: 'Status',
    draft: 'Draft',
    sent: 'Sent',
    paid: 'Paid'
  },
  template_view: {
    default: 'Default',
    customize: 'Customize',
    options: 'Options',
    logo: 'Logo',
    name: 'Name',
    text: 'Text',
    heading: 'Heading',
    show_our_branding_at_the_bottom_section: 'Show our branding at the bottom section',
    background_color: 'Backround color',
    text_color: 'Text color',
    border_color: 'Border color',
    font_size: 'Font size',
    font_weight: 'Font weight',
    line_height: 'Line height',
    headding_text_color: 'Heading text color',
    heading_line_height: 'Heading line height',
    heading_font_size: 'Heading font size',
    heading_font_weight: 'Heading font weight',
    customize_invoice_template: 'Customize invoice template',
    customize_proposal_template: 'Customize proposal template',
    invoice_templates: 'Invoice templates',
    proposal_templates: 'Proposal templates',
    set_as_default: 'Set as default'
  },
  settings_view: {
    title: 'Settings',
    entity_address_title: 'Entity address',
    settings: 'Settings',
    general: 'General',
    clients: 'Clients',
    proposals: 'Proposals',
    languages: 'Languages',
    invoices: 'Invoices',
    subscriptions: 'Subscriptions',
    entity_currency: 'Currency',
    entity_vat_id: 'VAT ID',
    entity_tax_id: 'TAX ID',
    entity_vat_percentage: 'VAT percentage',
    entity_name: 'Entity name',
    entity_country: 'Country',
    entity_address: 'Address',
    entity_city: 'City',
    entity_zipcode: 'Zipcode',
    entity_phone: 'Phone',
    entity_email: 'Email',
    entity_website: 'Website',
    contact_information: 'Contact information',
    client_id_format: {
      title: 'Client ID format',
      format: {
        counter_3_digits: 'Counter (3 Digits), e.g. 001',
        counter_6_digits: 'Counter (6 Digits), e.g. 001',
        month_base: 'Year base, e.g. 2024-01',
        day_base: 'Year base, e.g. 2024-01-18',
        timestamp: 'Timestamp, e.g. 1712839201611',
        client_base: 'Client-Based, e.g. 001-001'
      }
    },
    invoice_id_format: {
      title: 'Invoice ID format',
      invoice_template_text: 'Invoice template text'
    },
    proposal_id_format: {
      title: 'Proposal ID format',
      proposal_template_text: 'Proposal template text'
    },
    will_be_shown_on_invoice_and_proposal: 'Will be shown on invoices and proposals',
    primary_client_language: 'Primary client language',
    primary_client_language_help: 'Invoice will be created mainly using following language',
    secondary_client_languages: 'Secondary client languages',
    secondary_client_languages_help: 'The invoice might be also created in the following languages',
    app_interface_language: 'App interface language',
    app_interface_language_help: 'The language of the app interface'
  },
  notfound: {
    meta: {
      title: 'Page not found',
      description: 'Page not found'
    },
    title: 'Page not found'
  }
};

export default definitions;
