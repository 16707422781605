export enum RoleEnum {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  OWNER = 'owner',
  STAFF = 'staff'
}

export enum SettingGroupEnum {
  SYSTEM = 'system'
}

export const ActivityLogActionTranslation: Record<string, string> = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
  modify: 'modified',
  upload: 'uploaded'
} as const;

export enum InvoiceStatusEnum {
  CREATED = 'created',
  SENT = 'sent',
  PAID = 'paid'
}

export enum ProposalStatusEnum {
  CREATED = 'created',
  SENT = 'sent',
  APPROVED = 'approved'
}

export enum AppNameEnum {
  DASHBOARD_IMPERSONATING = 'dashboard_impersonating',
  ONLINE = 'online',
  DASHBOARD = 'dashboard'
}

export enum LanguageEnum {
  English = 'en',
  Spanish = 'es'
}
