import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { ContextMenuProvider } from 'mantine-contextmenu';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import BoundaryErrorFallback from './fallback';
import authClient from './lib/auth-client';
import RouteTable from './router';
import BrowserRouter from './router/BrowserHistory';
import { history } from './router/history';
import useAuthStore from './store/auth/auth.store';
import { theme } from './styles/theme';
import { Role } from './types/components';

const Router = () => {
  const { userInfo, setUserInfo } = useAuthStore();
  useEffect(() => {
    authClient.onAuthStateChange((_event: any, session: any) => {
      console.log(_event);
      setUserInfo({
        id: session?.id,
        name: session?.name,
        email: session?.email || userInfo?.email,
        role: session?.role as Role
      });
    });
  }, []);
  return (
    <BrowserRouter history={history}>
      <MantineProvider theme={theme} defaultColorScheme="auto">
        <ContextMenuProvider>
          <ErrorBoundary FallbackComponent={BoundaryErrorFallback}>
            <ModalsProvider>
              <Notifications position="top-center" limit={5} zIndex={999}></Notifications>
              <RouteTable />
            </ModalsProvider>
          </ErrorBoundary>
        </ContextMenuProvider>
      </MantineProvider>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <div className="App">
      <Router />
    </div>
  );
};

export default App;
