import Loadable from '@/components/loadable';
import { RoleEnum } from '@/types/enums';
import { Route } from '@/types/router';

export const adminAsyncRoutes: Route[] = [
  {
    path: '',
    name: 'index',
    component: Loadable({
      loader: () => import('@/modules/admin/dashboard')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'users',
    name: 'user',
    component: Loadable({
      loader: () => import('@/modules/admin/user/List')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'users/create',
    name: 'userCreate',
    component: Loadable({
      loader: () => import('@/modules/admin/user/Create')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'users/:id/update',
    name: 'userUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/user/Update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'templates',
    name: 'template',
    component: Loadable({
      loader: () => import('@/modules/admin/template/List')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'templates/create',
    name: 'templateCreate',
    component: Loadable({
      loader: () => import('@/modules/admin/template/Create')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'templates/:id/update',
    name: 'templateUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/template/Update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  },
  {
    path: 'price_plans',
    name: 'pricePlan',
    component: Loadable({
      loader: () => import('@/modules/admin/price-plan/List')
    }),
    meta: {
      roles: [RoleEnum.ADMIN]
    }
  }
];
