import { useTranslations } from '@/lib/i18n';
import { RoleEnum } from '@/types/enums';
import { Center, ScrollArea, Stack, useMantineTheme } from '@mantine/core';
import cx from 'clsx';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAuthStore from '../../store/auth/auth.store';
import { adminNavbarLinks } from '../nav-links/admin';
import { customerNavbarLinks } from '../nav-links/customer';
import { workspaceNavbarLinks } from '../nav-links/workspace';
import classes from './DefaultSidebar.module.css';
import { Footer } from './Footer';

export function DefaultSidebar() {
  const theme = useMantineTheme();
  const { userInfo } = useAuthStore();

  const { workspace_id } = useParams();
  const t = useTranslations();

  const [active, setActive] = useState('Dashboard');

  const navbarLinks = workspace_id
    ? workspaceNavbarLinks.map((l) => ({
        ...l,
        link: `/${workspace_id}${l.link}`,
        ...(l.childLinks && {
          childLinks: l.childLinks.map((c) => ({
            ...c,
            link: `/${workspace_id}${c.link}`
          }))
        })
      }))
    : userInfo?.role === RoleEnum.CUSTOMER
      ? customerNavbarLinks
      : adminNavbarLinks;

  return (
    <div className={classes.navbar}>
      <ScrollArea h="100%" className={classes.pad} type="auto">
        <div className={classes.links}>
          {navbarLinks.map((navbarLink) => (
            <Stack key={navbarLink.label} gap={0} style={{ borderRadius: theme.radius.sm, overflow: 'hidden' }}>
              <Link
                to={navbarLink.link}
                className={cx(classes.link, {
                  [classes.linkActive]: navbarLink.label === active,
                  [classes.linkDisabled]: userInfo?.role.includes('_editor') && navbarLink.disableForEditor
                })}
                onClick={() => setActive(navbarLink.label)}>
                <navbarLink.icon className={classes.linkIcon} size={20} />
                <span>{t(`nav.${navbarLink.label}`)}</span>
              </Link>
              {navbarLink.label === active &&
                (navbarLink.childLinks || []).map((childLink) => (
                  <Link
                    key={childLink.label}
                    to={childLink.link}
                    className={cx(classes.link, {
                      [classes.childLinkActive]: navbarLink.label === active,
                      [classes.linkDisabled]: userInfo?.role.includes('_editor') && childLink.disableForEditor
                    })}>
                    <Center style={{ width: 24, marginRight: theme.spacing.sm }}>
                      <div className={classes.childLinkDot} />
                    </Center>
                    <span>{t(`nav.${childLink.label}`)}</span>
                  </Link>
                ))}
            </Stack>
          ))}
        </div>
      </ScrollArea>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
}
