import fetchHelper from '@ckpack/fetch-helper';
import config from '../config';
import { token } from './token';

const httpClient = fetchHelper.create({
  baseURL: config.api.host,
  headers: {
    'Content-type': 'application/json'
  },
  async transformResponse({ response }) {
    if (Number(response?.status) >= 200 && Number(response?.status) <= 299) {
      return response && (response.json() as any);
    }

    throw new Error(await response?.text());
  },
  transformRequest({ init }) {
    const headers = new Headers(init.headers);
    const bearerToken = token.get();
    if (bearerToken) {
      headers.set('Authorization', `Bearer ${bearerToken?.currentSession?.session?.access_token}`);
    }

    const { body } = init;
    if (typeof body === 'object' && !(body instanceof FormData || body instanceof URLSearchParams)) {
      init.body = JSON.stringify(body);
    }

    if (init.timeout) {
      const controller = new AbortController();
      init.signal = controller.signal;
      setTimeout(() => controller.abort('timeout'), init.timeout);
    }

    init.headers = headers;

    return init;
  }
});

export { httpClient };
