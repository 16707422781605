import { version } from '../package.json';

const { VITE_API_SERVICE_URI, VITE_ENVIRONMENT } = import.meta.env;

export default {
  app: {
    version,
    environment: VITE_ENVIRONMENT
  },
  api: {
    host: VITE_API_SERVICE_URI
  }
};
