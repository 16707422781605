import { IApiResponse } from '../lib/api';
import { httpClient } from '../lib/http';
import { IUserInfo } from '../types/components';
import { retryPromise } from '../utils/retry-promise';

export const getMe = async () =>
  retryPromise({
    promise: async () => httpClient.get<IApiResponse<IUserInfo>>('/v1/me'),
    retryCount: 5
  });
