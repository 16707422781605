import { useTranslations } from '@/lib/i18n';
import { Flex, Loader, LoaderProps, MantineColor, MantineSize, Text } from '@mantine/core';
import { ReactNode } from 'react';

export type LoadingBoxProps = {
  color?: MantineColor;
  size?: MantineSize;
  variant?: LoaderProps['variant'];
  loaderProps?: LoaderProps;
  children: ReactNode;
};

const LoadingBox = (props: LoadingBoxProps) => {
  const t = useTranslations();
  return (
    <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap">
      <Loader variant={props.variant ?? 'bars'} color={props.color} size={props.size} {...props.loaderProps} />
      <Text>{props.children ?? `${t('common.loading')}...`}</Text>
    </Flex>
  );
};

export default LoadingBox;
