import { NavbarLink } from './interface';

import { IconCurrencyPound, IconDashboard, IconTemplate, IconUsers } from '@tabler/icons-react';

export const adminNavbarLinks: NavbarLink[] = [
  {
    link: '/',
    label: 'dashboard',
    icon: IconDashboard
  },
  {
    link: '/users',
    label: 'users',
    icon: IconUsers
  },
  {
    link: '/templates',
    label: 'templates',
    icon: IconTemplate
  },
  {
    link: '/price_plans',
    label: 'price_plans',
    icon: IconCurrencyPound
  }
];
