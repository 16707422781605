import { definitions } from './definitions';
import I18n from './helpers';

export const languages = {
  en: 'English',
  vi: 'Tiếng Việt'
};

export const defaultLang = 'en';

const langDict = new I18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: definitions
});

export function useTranslations(lang: string = defaultLang) {
  return function t(key: string) {
    return langDict.t(key, lang);
  };
}
