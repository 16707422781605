import Loadable from '@/components/loadable';
import { RoleEnum } from '@/types/enums';
import { Route } from '@/types/router';

export const workspaceAsyncRoutes: Route[] = [
  {
    path: ':workspace_id',
    name: 'workspaceIndex',
    component: Loadable({
      loader: () => import('@/modules/workspace/overview')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/clients',
    name: 'workspaceClient',
    component: Loadable({
      loader: () => import('@/modules/workspace/client/List')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/clients/create',
    name: 'workspaceClientCreate',
    component: Loadable({
      loader: () => import('@/modules/workspace/client/Create')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/clients/:client_id',
    name: 'workspaceClientUpdate',
    component: Loadable({
      loader: () => import('@/modules/workspace/client/Update')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/invoices',
    name: 'workspaceInvoice',
    component: Loadable({
      loader: () => import('@/modules/workspace/invoice/List')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/invoices/templates',
    name: 'workspaceInvoiceTemplate',
    component: Loadable({
      loader: () => import('@/modules/workspace/template/Invoices')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/invoices/templates/:template_id',
    name: 'workspaceInvoiceTemplateSetting',
    component: Loadable({
      loader: () => import('@/modules/workspace/template/CustomizeInvoice')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/invoices/create',
    name: 'workspaceInvoiceCreate',
    component: Loadable({
      loader: () => import('@/modules/workspace/invoice/Create')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/invoices/:invoice_id',
    name: 'workspaceInvoiceUpdate',
    component: Loadable({
      loader: () => import('@/modules/workspace/invoice/Update')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/proposals',
    name: 'workspaceProposal',
    component: Loadable({
      loader: () => import('@/modules/workspace/proposal/List')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/proposals/templates',
    name: 'workspaceProposalTemplate',
    component: Loadable({
      loader: () => import('@/modules/workspace/template/Proposals')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/proposals/templates/:template_id',
    name: 'workspaceProposalTemplateSetting',
    component: Loadable({
      loader: () => import('@/modules/workspace/template/CustomizeProposal')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/proposals/create',
    name: 'workspaceProposalCreate',
    component: Loadable({
      loader: () => import('@/modules/workspace/proposal/Create')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/proposals/:proposal_id',
    name: 'workspaceProposalUpdate',
    component: Loadable({
      loader: () => import('@/modules/workspace/proposal/Update')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/settings',
    name: 'workspaceEntity',
    component: Loadable({
      loader: () => import('@/modules/workspace/setting/General')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/settings/clients',
    name: 'workspaceClient',
    component: Loadable({
      loader: () => import('@/modules/workspace/setting/Client')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/settings/invoices',
    name: 'workspaceInvoice',
    component: Loadable({
      loader: () => import('@/modules/workspace/setting/Invoice')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/settings/proposals',
    name: 'workspaceProposal',
    component: Loadable({
      loader: () => import('@/modules/workspace/setting/Proposal')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/settings/subscriptions',
    name: 'workspaceSubscription',
    component: Loadable({
      loader: () => import('@/modules/workspace/setting/Subscription')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  },
  {
    path: ':workspace_id/settings/languages',
    name: 'workspaceLanguage',
    component: Loadable({
      loader: () => import('@/modules/workspace/setting/Language')
    }),
    meta: {
      roles: [RoleEnum.OWNER, RoleEnum.STAFF]
    }
  }
];
