import { NavbarLink } from './interface';

import { IconBuilding, IconCash, IconUser } from '@tabler/icons-react';

export const customerNavbarLinks: NavbarLink[] = [
  {
    link: '/',
    label: 'workspaces',
    icon: IconBuilding
  },
  {
    link: '/profile',
    label: 'my_profile',
    icon: IconUser
  },
  {
    link: '/billing',
    label: 'billing',
    icon: IconCash
  }
];
