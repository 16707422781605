import { useTranslations } from '@/lib/i18n';
import { impersonateToken } from '@/lib/token';
import {
  ActionIcon,
  Avatar,
  Box,
  Burger,
  Group,
  Image,
  Menu,
  Text,
  UnstyledButton,
  useMantineColorScheme
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Icon, IconChevronDown, IconLogout, IconMoon, IconSettings, IconSun } from '@tabler/icons-react';
import cx from 'clsx';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { version } from '../../package.json';
import logoImage from '../assets/logo.png';
import authClient from '../lib/auth-client';
import useAuthStore from '../store/auth/auth.store';
import useSidebarStore from '../store/sidebar/sidebar.store';
import classes from './DefaultHeader.module.css';

interface HeaderLink {
  link: string;
  label: string;
  icon: Icon;
  target?: string;
}

const headerLinks: HeaderLink[] = [];

export function DefaultHeader() {
  const navigate = useNavigate();
  const t = useTranslations();

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const { userInfo } = useAuthStore();
  const { collapsed, setSidebarCollapsed } = useSidebarStore();

  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const isImpersonated = Boolean(impersonateToken.get());

  const headerLinksFragment = headerLinks.map((headerLink) => (
    <Link key={headerLink.label} to={headerLink.link} target={headerLink.target} className={classes.link}>
      <headerLink.icon size={16} style={{ marginRight: 7.5 }} />
      {headerLink.label}
    </Link>
  ));

  function handleEndImpersonate() {
    if (isImpersonated) {
      authClient.endImpersonate();
      location.href = '/';
    }
  }

  const handleSignoutButton = () => {
    if (userInfo) {
      authClient
        .signOut()
        .then(() => {
          navigate('/login');
        })
        .catch(() =>
          notifications.show({
            color: 'red',
            title: t('common.error'),
            message: t('common.error_occured')
          })
        );
    }
  };

  return (
    <Group justify="space-between">
      <Group>
        <Burger opened={collapsed} onClick={() => setSidebarCollapsed(!collapsed)} size="sm" />

        <Box component={Link} to="/">
          <Image src={logoImage} height={40} />
        </Box>
      </Group>

      <Group>
        <Group ml={50} gap={5} className={classes.links}>
          {headerLinksFragment}
        </Group>

        <Group>
          <ActionIcon
            onClick={() => toggleColorScheme()}
            size="lg"
            style={(theme) => ({
              backgroundColor: colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
              color: colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.blue[6]
            })}>
            {colorScheme === 'dark' ? <IconSun size="1.2rem" /> : <IconMoon size="1.2rem" />}
          </ActionIcon>
        </Group>

        <Group gap="xs">
          <Menu
            width={200}
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal>
            <Menu.Target>
              <UnstyledButton className={cx(classes.user, { [classes.userActive]: userMenuOpened })}>
                <Group gap={7}>
                  <Avatar alt={userInfo?.name} src={userInfo?.avatar} radius="xl" size={24} />
                  <Text fw={500} size="sm" style={{ lineHeight: 1 }} mr={3}>
                    {`${userInfo?.name}`}
                  </Text>
                  <IconChevronDown size={12} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>{t('header.settings')}</Menu.Label>
              <Menu.Item leftSection={<IconSettings size="0.9rem" />} component={Link} to="/profile">
                {t('header.account')}
              </Menu.Item>
              {isImpersonated ? (
                <Menu.Item leftSection={<IconLogout size="0.9rem" />} onClick={handleEndImpersonate}>
                  {t('header.end_impersonate')}
                </Menu.Item>
              ) : (
                <Menu.Item leftSection={<IconLogout size="0.9rem" />} onClick={handleSignoutButton}>
                  {t('header.logout')}
                </Menu.Item>
              )}

              <Menu.Divider />
              <Menu.Label>{t('header.version')}</Menu.Label>
              <Menu.Item>{`v${version}`}</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </Group>
  );
}
