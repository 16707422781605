import { NavbarLink } from './interface';

import { IconInvoice, IconNotes, IconReportMoney, IconSettings, IconUsers } from '@tabler/icons-react';

export const workspaceNavbarLinks: NavbarLink[] = [
  {
    link: '/',
    label: 'overview',
    icon: IconReportMoney
  },
  {
    link: '/clients',
    label: 'clients',
    icon: IconUsers
  },
  {
    link: '/invoices',
    label: 'invoices',
    icon: IconInvoice,
    childLinks: [
      {
        link: '/invoices',
        label: 'invoices'
      },
      {
        link: '/invoices/templates',
        label: 'templates'
      }
    ]
  },
  {
    link: '/proposals',
    label: 'proposals',
    icon: IconNotes,
    childLinks: [
      {
        link: '/proposals',
        label: 'proposals'
      },
      {
        link: '/proposals/templates',
        label: 'templates'
      }
    ]
  },
  {
    link: '/settings',
    label: 'settings',
    icon: IconSettings
  }
];
