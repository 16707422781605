const definitions = {
  common: {},
  notfound: {
    meta: {
      title: 'Không tìm thấy trang',
      description: 'Không tìm thấy trang'
    },
    title: 'Không tìm thấy trang'
  }
};

export default definitions;
