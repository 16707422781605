import type { Params } from './util';
import { formatMessage } from './util';

export type Message = Record<string, any>;
export type Messages = Record<string, Message>;

class I18n {
  locale: string;

  fallbackLocale: string;

  messages: Messages;

  constructor(params: { locale: string; fallbackLocale?: string; messages: Messages }) {
    const { locale, fallbackLocale, messages } = params;
    this.locale = locale;
    this.fallbackLocale = fallbackLocale || locale;
    this.messages = messages || {};
  }

  t(key: string, locale?: string, params?: Params) {
    const keys = key.split('.');
    const message =
      this.getMessagesByLocale(keys, locale ?? this.locale) || this.getMessagesByLocale(keys, this.fallbackLocale);
    return formatMessage(message, params) || key;
  }

  private getMessagesByLocale(keys: string[], locale: string) {
    return keys.reduce((val, cur) => {
      if (val && cur in val) {
        return val[cur];
      }
      return undefined;
    }, this.messages[locale]);
  }

  setLocale(locale: string) {
    if (Object.keys(this.messages).indexOf(locale) !== -1) {
      this.locale = locale;
      return true;
    }
    return false;
  }

  setLocaleMessage(locale: string, message: Message) {
    this.messages[locale] = message;
  }
}

export default I18n;
